import { defineMessages } from 'react-intl'

const messages = defineMessages({
  app_name: 'Uclusion',
  support: 'Support',
  page_not_found_demo: 'Page not found demo',
  404: '404',
  warning_404_message: '404 Page not found',
  warning_404_description: 'We are sorry but the page you are looking for does not exist.',
  warning_404_categories: 'No categories configured for this market.',
  warning: 'Warning',
  blockedNotice: 'Operation Blocked',
  slack_register_failed: 'Slack registration failure.',
  settings: 'Settings',
  workspaceSettings: 'Workspace settings',
  oneDoneInvestible: 'Assignment',
  language: 'Language',
  theme: 'Theme',
  responsive: 'Responsive',
  en: 'English',
  de: 'Deutsch',
  bs: 'Bosanski',
  ru: 'Русский',
  es: 'Español',
  dark: 'Dark',
  light: 'Light',
  default: 'Default',
  green: 'Green',
  red: 'Red',
  sign_out: 'Sign out',
  day_mode: 'Day Mode',
  daysLeft: 'days',
  hoursLeft: 'hours',
  minutesLeft: 'minutes',
  night_mode: 'Night Mode',
  sign_in: 'Sign in',
  sign_up: 'Sign up',
  continue: 'Continue',
  reset_password_header: 'Reset Password',
  login_header: 'Log In',
  sign_in_with_google: 'Sign in with Google',
  sign_in_with_facebook: 'Sign in with Facebook',
  sign_in_with_twitter: 'Sign in with Twitter',
  sign_in_with_github: 'Sign in with Github',
  my_account: 'My account',
  name: 'Name',
  adminName: 'Name of new admin',
  email: 'E-Mail',
  password: 'Password',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  forgort_password: 'Forgot Password?',
  reset_password: 'Reset Password',
  change_password: 'Change Password',
  change_photo: 'Change Photo',
  change_email: 'Change E-Mail',
  reset_password_hint: 'Enter your E-Mail',
  save: 'Save',
  delete_account: 'Delete account',
  select_file: 'Select file',
  cancel: 'Cancel',
  submit: 'Submit',
  delete: 'Delete',
  ok: 'OK',
  help: 'Help Guide',
  email_not_verified: 'E-Mail is not verified!',
  email_verified: 'E-Mail is verified',
  send_verification_email: 'Send verification E-Mail',
  send_verification_email_again: 'Send verification E-Mail again',
  check_email_code: 'Please check your email for a verification code',
  investibles: 'Investibles',
  users: 'Users',
  manage: 'Manage Collaborators',
  edit: 'Edit',
  configure: 'Configure',
  configureVoting: 'Configure voting',
  changeCompletionDate: 'Change Completion Date',
  edit_lock: 'Someone else is editing!',
  online: 'Online',
  offline: 'Offline',
  no_connection_warning: 'No connection!',
  title_label: 'Title',
  title_hint: 'Enter title',
  no_connection: 'No connection',
  error: 'Error!',
  description_label: 'Description',
  description_hint: 'Enter description',
  name_label: 'Name',
  name_hint: 'Enter name',
  required: 'Required',
  github: 'Github',
  phone: 'Phone',
  google: 'Google',
  facebook_color: '#303F9F',
  github_color: '#263238',
  twitter_color: '#36A2EB',
  phone_color: '#90A4AE',
  google_color: '#EA4335',
  password_color: '#4CAF50',
  chats: 'Chats',
  write_message_hint: 'Write message...',
  load_more_label: 'More...',
  my_location: 'My Location',
  select_user: 'Select user',
  operator_like_label: 'like',
  operator_notlike_label: 'not like',
  operator_equal_label: 'equal',
  operator_notequal_label: 'not equal',
  operator_novalue_label: 'no value',
  administration: 'Administration',
  roles: 'Roles',
  grants: 'Grants',
  private: 'Private',
  public: 'Public',
  created_by: 'Created by',
  observers: 'Approver:',
  dialogParticipants: 'collaborators',
  dialogObservers: 'Observers',
  unassigned: 'unassigned',
  isObserver: 'Notifications off',
  browserNotSupported: 'Currently only the latest Chrome browser is supported',
  addDecision: 'Add Dialog',
  addPlanning: 'Add Group',
  addInitiative: 'Add Initiative',
  author: 'author',
  done: 'Done',
  selectDate: 'Click to select a date',
  advanced: 'Advanced',
  close: 'Close',
  typeOrChoose: 'Type and hit enter or choose from dropdown',
  addLabel: 'Add label',
  created: 'Created ',
  edited: 'Storing locally',
  hours: 'hours',
  days: 'days',
  points: 'points',
  currency: 'dollars',
  update: 'Update',
  welcome: 'Get started',

  // Upgrade
  upgradeNow: 'Upgrade Now',
  upgradeNowDialog: 'Upgrade to Add Dialog',
  upgradeBannerText: 'Your free plan has expired and some functionality is not available.',

  // Share story
  shareButtonExplanation: 'Link',
  shareButtonTitle: 'Share',
  copyLink: 'Link',
  copyInviteLink: 'Copy Invite Link',
  inviteLinkerTicketCode: 'Human friendly job identifier',

  // Support
  supportInfoText: 'Send an email to <a>support</a> which includes the version and user ID above.',
  featureRequest: 'Feature Request',
  createFeatureRequest: 'Create feature request',
  createOnboardingWorkspace: 'Support Help',

  archiveInlineTitle: 'Dialog',
  gettingLockMessage: 'Obtaining lock',
  creatingDemoMessage: "Hang tight, we're creating a demo just for you",
  loadingMessage: 'Page loading',
  demoLoadingMessage: 'Please wait while your sandbox demo is downloaded - this may take some time...',
  marketLoadingMessage: 'Please wait while your workspace invitation is processed...',
  billingLoadingMessage: 'Please wait while your billing information is loading...',
  invoicesLoadingMessage: 'Please wait while your invoices are loading...',
  promosLoadingMessage: 'Please wait while your promotions are loading...',
  commentLoadingMessage: 'Comment loading',
  commentOptionsLoadingMessage: 'Comment options loading',
  loadingMarket: 'Processing invite',
  loadingSlack: 'Slack integration',

  // Market Add
  marketAddTitleDefault: 'Add a name...',
  marketAddTitleLabel: 'Name',
  marketAddDescriptionDefault: 'Add a description...',
  marketAddCancelLabel: 'Cancel',
  marketAddSaveLabel: 'Save',
  investmentExpirationInputLabel: 'Number of days before an approval expires',
  daysEstimateInputLabel: 'Very rough number of days to complete',
  investmentExpiration: 'before approval expires',
  initiativeExpiration: 'before initiative expires',
  decisionExpiration: 'before dialog expires',
  storyTitlePlaceholder: 'Name your job',
  optionTitlePlaceholder: 'Name your option',
  agilePlanFormTitlePlaceholder: 'Name your group',
  decisionTitlePlaceholder: 'Name your dialog',
  initiativeTitlePlaceholder: 'Name your initiative',
  agilePlanFormTitleLabel: 'Name',
  agilePlanFormFieldsetLabelOptional: 'Optional',
  agilePlanFormFieldsetLabelRequired: 'Required',
  agilePlanFormDaysEstimateLabel: 'Days estimate',
  agilePlanFormDaysEstimatePlaceholder: 'Very rough number of days to complete',
  agilePlanFormInvestmentExpirationLabel: 'Approval Expiration in days',
  startedExpirationLabel: 'Accepted expiration in days',
  agilePlanFormSaveLabel: 'Save',

  // Market Nav
  marketNavTabContextLabel: 'Context',
  marketNavTabAddIdeaLabel: 'Add New',

  // Market Edit
  marketEditTitleLabel: 'Name',
  marketEditCancelLabel: 'Clear',
  marketEditSaveLabel: 'Save',

  editMarketButtonPlan: 'Workspace settings',
  editMarketButtonDecision: 'Dialog settings',
  editMarketButtonInitiative: 'Edit initiative',

  // InvestibleEditButton
  investibleEditButtonTooltip: 'Edit',
  daysEstimateLabel: 'effort estimate',

  // CurrentVoting
  removeNotifications: 'Mark all read',
  numVoting: '{x} approvals',
  certainty100: 'Very Certain',
  certainty75: 'Certain',
  certainty50: 'Somewhat Certain',
  certainty25: 'Somewhat Uncertain',
  certainty5: 'Uncertain',
  certaintyQuestion: 'Rate Your Certainty',
  reasonQuestion: 'Why did you approve for this option?',
  saveVote: 'Approve',
  updateVote: 'Update approval',
  saveReject: 'Reject',
  rejectAssignment: 'Reject assignment',
  keepReject: 'Stay reject',
  keepFor: 'Stay approve',
  switchToFor: 'Switch to approve',
  switchToReject: 'Switch to reject',
  updateReject: 'Update rejection',
  yourReason: 'Your reason...',
  cancelVote: 'Cancel',
  removeVote: 'Remove approval',
  removeReject: 'Remove rejection',
  draft: 'Draft',
  guest: 'Guest',
  inactive: 'Inactive',

  // Navigation menu
  swimLanes: 'Swimlanes',
  inbox: 'Inbox',
  unread: 'For You',
  teamUnresolved: 'Snoozed',
  outbox: 'From You',
  seeFullInbox: 'SEE FULL',
  seeFullOutbox: 'SEE FULL',
  seeInbox: 'View Inbox',
  seeOutbox: 'View Pending',
  commentAddBox: 'Add Comment',
  questions: 'Questions',
  suggestions: 'Suggestions',
  reports: 'Reports',
  blocking: 'Blockers',
  workspace: 'Workspace',
  story: 'Job',
  approvals: 'Approvals',
  pleaseVoteNav: 'Change Approval',
  dialogs: 'Dialogs',
  home: 'Home',
  addNew: 'Add New',
  initiatives: 'Initiatives',
  dialog: 'Dialog',
  approvable: 'Approvable',
  proposed: 'Proposed',
  option: 'Option',
  proposedOption: 'Proposed Option',
  initiative: 'Initiative',
  for: 'For',
  against: 'Against',
  closedComments: 'Resolved',
  commentReplyEdit: 'Comment Edit',
  labels: 'Labels',
  previousNavigation: 'Go to previous navigation',
  nextNavigation: 'Go to the next most likely thing that needs doing',

  //Inbox notification titles
  unfinished: 'Unfinished',
  unfinishedMobile: 'Unfinished',
  reportRequired: 'Status due',
  reportRequiredMobile: 'Status',
  assignTodo: 'Unassigned bug',
  assignTodoMobile: 'Unassigned',
  assignTask: 'Unassigned job',
  assignTaskMobile: 'Unassigned',
  unreadAttachment: 'Attachments modified',
  unreadAttachmentMobile: 'Modified',
  unreadName: 'Name changed',
  unreadNameMobile: 'Modified',
  unreadDescription: 'Description changed',
  unreadDescriptionMobile: 'Modified',
  unreadEstimate: 'Okay estimate?',
  unreadEstimateMobile: 'Okay estimate?',
  unreadJobEdit: 'Job edited',
  unreadAssignment: 'Assigned to You',
  unreadAssignmentMobile: 'Assigned',
  unreadOption: 'Consider option',
  unreadOptionMobile: 'Option',
  issue: 'Blocking issue',
  issueMobile: 'Blocking',
  unPromotedOption: 'Unpromoted option',
  unPromotedOptionMobile: 'Unpromoted',
  workspaceClosed: 'Workspace muted',
  workspaceClosedMobile: 'Muted',
  pleaseChoose: 'Question',
  pleaseChooseMobile: 'Question',
  pleaseVote: 'Suggestion',
  pleaseVoteMobile: 'Suggestion',
  pleaseApprove: 'Decision',
  pleaseApproveMobile: 'Decision',
  resolveTodo: 'New task',
  resolveTodoMobile: 'New task',
  removed: 'Removed from workspace',
  removedMobile: 'Removed',
  unRemoved: 'Added to workspace',
  unRemovedMobile: 'Added',
  pleaseReview: 'Please review',
  pleaseReviewMobile: 'Review',
  pleaseAssign: 'Please assign',
  pleaseAssignMobile: 'Assign',
  updateStatus: 'Please update status',
  updateStatusMobile: 'Status',
  addCollaborators: 'Please add collaborators',
  addCollaboratorsMobile: 'Collaborators',
  pleaseUpgrade: 'Please upgrade',
  pleaseUpgradeMobile: 'Upgrade',
  unreadReply: 'Will you reply?',
  unreadMention: 'Will you respond?',
  unreadReplyMobile: 'Reply',
  unreadResolved: 'Resolved comment',
  unreadResolvedMobile: 'Resolved',
  unreadComment: 'View comment',
  unreadCommentMobile: 'Comment',
  unreadVote: 'New vote',
  unreadVoteMobile: 'Vote',
  unreadApproval: 'New approval',
  unreadApprovalMobile: 'Approval',
  fullyVoted: 'Fully voted',
  fullyVotedMobile: 'Voted',
  feedback: 'In feedback',
  noNew: 'No notifications.',
  noPending: 'Nothing pending.',
  enjoy: 'Enjoy your day',
  aboutInbox: 'Get a job approved',
  rowExpand: 'Click this row to expand.',
  rowCollapse: 'Click this row to collapse.',
  rowExpandComment: 'Click this comment to expand.',
  notificationSummary: 'Summary',
  viewInGroup: 'Group views',
  viewInGroupTip: 'Displays job status, backlog, bugs, and discussion for each group.',
  inactiveGroups: 'Inactive groups',

  // Address list
  addressAddCancelLabel: 'Close',
  addressAddClearLabel: 'Close',
  addressAddSaveLabel: 'Send',
  inviteParticipantsLabel: 'Invite by email',
  addExistingCollaborator: 'Add Selected',
  addExistingCollaboratorMobile: 'Add',
  investibleAddOthersExplanation: 'Add desired collaborators from outside this group.',

  // InvestibleAdd
  investibleAddTitleDefault: 'Add a name...',
  investibleAddTitleLabel: 'Name',
  investibleAddDescriptionDefault: 'Add a description...',
  investibleAddCancelLabel: 'Cancel',
  investibleAddSaveLabel: 'Save',
  skipApprovalExplanation: 'Skip getting approvals',
  newStory: 'New Job',
  DecideAssignMe: 'Assign to me',
  jobTypeLabelIMMEDIATE: 'Immediate - assign now',
  jobTypeLabelREADY: 'Ready to start - notify in backlog',
  jobTypeLabelNOT_READY: 'Not ready - backlog no notification',
  TaskLocalMoveLabel: 'Convert to task in current job',
  TaskOtherMoveLabel: 'Move to task in other job',
  SuggestionLocalMoveLabel: 'Convert to suggestion in current job',
  SuggestionOtherMoveLabel: 'Move to suggestion in other job',
  TaskLabel: 'Task',
  SuggestionLabel: 'Suggestion',
  BugLabel: 'Bug',
  BugMoveLabel: 'Convert to bug',
  JobMoveLabel: 'Move to task in job',
  DiscussionMoveLabel: 'Move to discussion',
  backlogReadyToStart: 'Backlog - Ready to Start',
  backlogNotReadyToStart: 'Backlog - Not Ready',
  jobCreate: 'Create job',

  // Investible
  investibleEditLabel: 'Edit',
  investibleAssignLabel: 'Assign',
  homeAssignments: 'Stories',
  investibleAssign: 'Job - Assignments',
  investibleFollow: 'Job - Following',
  investibleReviewers: 'Job - Required Reviewers',
  investibleApprovers: 'Job - Required Approvers',
  createAssignment: 'Assign',
  createAssignmentExplanation: 'Assign new job to this person.',
  investibleAssignForVotingLabel: 'Assign & Move Voting',
  investibleAddHeader: 'Add Investible',
  investibleEditStageHelper: 'Click to change stage',
  investibleEditInvestibleFetchFailed: 'There was a problem loading your investible for edit. Please try again later',
  investibleAddToVotingLabel: 'Move to Voting',
  investibleAddToVotingExplanation: 'Move to approvable option. Can be moved back to proposed later.',
  investibleRemoveFromVotingTitle: 'Move Out of Voting',
  investibleDeleteLabel: 'Permanently Delete',
  investibleDeleteExplanationLabel: 'Permanently remove this option from the dialog',
  planningInvestibleToVotingLabel: 'Assigned',
  planningMobileToVotingLabel: 'Approval',
  inboxVotingLabel: 'In approval',
  planningInvestibleNextStageAcceptedLabel: 'Approved',
  planningAcceptedStageLabel: 'Approved',
  startJobQ: 'Enough approvals?',
  finishApprovalQ: 'Done with approval?',
  otherOptionsQ: 'What to do with this job?',
  rejectAssignmentQ: 'Where to send this job?',
  planningInvestibleNextStageInReviewLabel: 'Tasks Complete',
  planningInvestibleMoveToVerifiedLabel: 'Tasks Complete',
  planningInvestibleMoveToFurtherWorkLabel: 'Backlog',
  planningInvestibleMoveToNotDoingLabel: 'Not Doing',
  DecideStageMove: 'Move',
  planningInvestibleNotDoingExplanation: 'Move to Not Doing will unassign and archive the job',
  planningVotingStageLabel: 'Assigned',
  verifiedBlockedStageLabel: 'Verified',
  planningReviewStageLabel: 'Tasks Complete',
  approvalsCountExplanation: 'Not enough approvals',
  inputRequiredCountExplanation: 'Open questions or suggestions for assignee',
  requiredReviewsCountExplanation: 'Open required reviews',
  planningVerifiedStageLabel: 'Tasks Complete',
  planningFurtherWorkStageLabel: 'Backlog',
  requiresInputStageLabel: 'Assistance',
  planningNotDoingStageLabel: 'Not Doing',
  planningBlockedStageLabel: 'Assistance',
  allowedStagesDropdownLabel: 'Stage',
  planningInvestibleAssignments: 'Assigned',
  requiredApprovers: 'Required approvers',
  addressed: 'Outside',
  planningInvestibleDecision: 'Add Dialog',
  missingEstimatedCompletion: 'No completion date',
  estimatedCompletionToday: 'Due',
  estimatedCompletionJustText: 'job due',
  planningInvestibleDescription: '- Description',
  mustRemoveTodosExplanation: 'Cannot change to this stage with open tasks',
  mustResolveAssignedQuestions: 'Cannot change to this stage with unresolved questions from an assignee',
  daysEstimateHelp: 'Optional very rough expected date of completion.',
  daysEstimateMarketLabel: 'date of completion',
  ticketSubCodeHelp: 'A short code for the group which will be used to form human readable job ids.',
  ticketSubCodeHelp1: 'Your job ids will look like U-{code}-0, U-{code}-1, U-{code}-2, etc.',
  groupNameHelp: 'A name for this group used in navigation and notification.',
  groupExplanationTooltip: 'A group is a subset of workspace members.',
  workspaceExplanationTooltip: 'Members of a workspace see everything in all groups.',
  voteExpirationHelp: "Days before approvals expire. Prevents approvals becoming too old.",
  startedExpirationHelp: "Days a job can be in Approved without progress report or estimated due date.",
  reassignToMove: 'Assigning moves to the swimlanes.',
  marketLinksSection: 'links',
  attachedFilesSection: 'Attach Files',
  parentLinkSection: 'Parent',
  dialogNotParticipating: 'Click to become a collaborator',
  lockedBy: 'Locked by {x}',
  lockFailedWarning: 'Failed to acquire lock. Retry?',
  breakLock: 'Break lock',
  noVoters: 'None',
  reassignWarning: 'Re-assigning will remove all approval.',
  planningUnacceptedLabel: 'Unaccepted',
  planningAcceptLabel: 'Accept',
  wizardAcceptLabel: 'Move to task',
  moveToSuggestion: 'Move to suggestion',
  planningAcceptExplanation: 'Has not accepted this job.',
  // Issues
  issueResolveLabel: 'Resolve',
  issueReplyLabel: 'Reply',
  issueProceed: 'Proceed',
  requiresInputWarning: 'With these assignments, some comments require input.',
  proceedRequiresInput: 'Assistance',
  resolveComment: 'Resolve Comments',
  commentCloseNewStage: 'Resolving moves job to {x}.',
  numRepliesExplanation: 'Number in thread',
  // lock dialog
  lockDialogCancel: 'Cancel',
  pageLockEditPage: 'Edit Page',
  lockDialogTitle: 'Page locked',
  lockDialogContent: 'A user is currently editing this page, will you to take over editing?',

  // CommentAdd
  commentAddIssueDefault: 'Your issue...',
  commentAddTODODefault: 'Your task...',
  commentAddBugDefault: 'Your bug...',
  commentAddQuestionDefault: 'Your question...',
  commentAddSuggestDefault: 'Your suggestion...',
  commentAddReplyDefault: 'Your reply...',
  commentAddReportDefault: "Report progress here, Ex: 'Made the button blue'",
  commentAddReportReview: "Explain what should be reviewed, Ex: 'Button is blue now.'",
  commentAddReviewReportDefault: 'Write your feedback here',
  commentAddSaveLabel: 'Save',
  commentAddSendLabel: 'Send',
  commentAddSendResolve: 'Send and resolve',
  commentAddCancelLabel: 'Clear',
  commentAddSelectIssueLabel: 'Please select a type',
  issueWarning: 'Opening an issue will halt voting on this dialog.',
  noType: 'Choose a comment type.',
  proceedNormal: 'Everyone',
  proceedRestricted: 'Just me',
  removeCompressionExplanation: 'Show all comments.',

  // CommentBox
  commentIconRaiseIssueLabel: 'Raise issue',
  commentIconAskQuestionLabel: 'Ask question',
  commentIconSuggestChangesLabel: 'Suggest changes',

  // Comments
  commentReplyDefault: 'Your reply...',
  commentReplyLabel: 'Reply',
  commentEditLabel: 'Edit',
  commentReplyCancelLabel: 'Cancel',
  commentReopenLabel: 'Reopen',
  commentResolveLabel: 'Resolve',
  commentResolveLabelInstead: 'Resolve instead',
  commentResolveLabelOnly: 'Resolve only',
  UnblockReplyLabel: 'Reply',
  commentAbstainLabel: 'Mute',
  commentUnmuteLabel: 'Unmute',
  commentAbstainingLabel: 'Muted',
  snoozedLabel: 'Snoozed',
  poke: 'Poke',
  poked: 'Poked',
  pokedExplanation: 'This notification was poked by its author to increase urgency.',
  pokedBugExplanation: 'This bug was poked by its author to increase urgency.',
  commentRemoveLabel: 'Delete',
  commentCloseThreadLabel: 'Collapse',
  lastUpdatedBy: ' by',
  comments: 'Comments',
  JobCommentConfigure: 'Create option',
  JobCommentCreateAnotherOption: 'Create and add another',
  JobOptionTerminate: 'Go to question',
  DecideStartNavigate: 'Go to bug',
  DiscussionCommentAddQUESTION: 'Add options',
  DiscussionCommentAddSUGGEST: 'Add suggestion',
  JobCommentAddQUESTION: 'Add options',
  JobCommentAddSUGGEST: 'Add',
  JobCommentAddISSUE: 'Add',
  JobCommentAddTODO: 'Add',
  JobCommentAddREPORT: 'Add',
  addAnother: 'Add and another',
  DecisionCommentAddQUESTION: 'Add',
  DecisionCommentAddSUGGEST: 'Add',
  DecisionCommentAddISSUE: 'Add',

  // card types
  cardTypeLabelIssue: 'Blocking issue',
  cardTypeLabelQuestion: 'Question',
  cardTypeLabelSuggestedChange: 'Suggestion',
  cardTypeLabelTodo: 'task',
  cardTypeLabelProgressReport: 'progress report',
  cardTypeAgilePlan: 'group - description',

  // Notices
  noticeNewApplicationVersion: 'A new version of the application is available! It will load when you close this message.',
  noticeVersionForceLogout: 'This version of the application requires signout which will happen when you close this message.',
  notifications: 'notifications',
  notification: 'notification',
  notificationDelete: 'Dismiss',
  informational: 'Informational',
  mentionsExplanation: 'Count of mentions for this person to process',
  approvalExplanation: 'Count of required approvals for this person to process',
  backToInboxWizard: 'Back to inbox wizard',

  // stages
  marketStageFollowTooltip: 'Subscribe',
  marketStageUnFollowTooltip: 'Unsubscribe',
  approvedChangeStage: 'Move to Approved',

  // markets
  signupInvite: 'invites you to collaborate in',
  signupRedirectLogin: 'Create account will redirect you to login.',
  switchWorkspace: 'Switch workspaces',
  editWorkspace: 'Edit workspace',
  notificationMarketSettingsExplanation: 'Change notification settings of the chosen workspace',

  // Rich text editor
  RichTextEditorAddLinkTitle: 'Add Link',
  RichTextEditorEnterUrl: 'Enter the URL of the link:',
  RichTextEditorEnterTextAndLink: 'Enter the URL and text of the link:',
  RichTextEditorLinkLabel: 'Link',
  RichTextEditorTextLabel: 'Text',
  RichTextEditorToManyBytes: 'To much data. Please remove items from the editor',

  // Investible detail
  investibleDetailClose: 'Close',
  chooseDate: 'Change estimated completion date',
  orProgressReport: 'or create or update a progress report.',
  orProgressReportOnly: 'or create a progress report.',
  descriptionVotingLabel: 'Overview',
  planningInvestibleOpenLabel: 'Details',

  // Home
  homeAddPlanning: 'New workspace',
  homeAddGroup: 'New workspace group',
  archiveWarning: 'Muting will unassign any active job. Deactivating stops the group for everyone.',
  new: 'new',
  match: 'match',
  votes: 'votes',
  tasks: 'tasks',
  review: 'review',
  open: 'open',
  total: 'total',
  openHeader: 'Open',
  information: 'Information',
  message: 'Message',
  // Archives
  archivesTitle: 'Archive',
  inboxMarkRead: 'Mark selected read',
  outboxMark: 'Poke selected',
  inboxArchive: 'Delete selected',
  inboxCollapseAll: 'Collapse All',
  inboxExpandAll: 'Expand All',
  backToInbox: 'Back to inbox',

  // Planning Dialog
  planningDialogNavDetailsLabel: 'Details',
  planningDialogNavStoriesLabel: 'Assigned Jobs',
  planningDialogBacklog: 'Job Backlog',
  planningDialogNavDiscussionLabel: 'Discussion',
  planningDialogManageParticipantsLabel: 'Manage collaborators',
  planningDialogDiscussionLabel: 'Discussion',
  todosSelectForStory: 'Select bugs for job',
  todosCreateStory: 'Create job from bugs',
  todosMove: 'Move tasks',
  planningDialogAddInvestibleExplanation: 'Create a job and collaborate on what to do',
  planningDialogViewArchivesExplanation: 'See archived comments and jobs in Complete and Not Doing',
  planningDialogViewArchivesLabel: 'Archive',
  seeArchives: 'See muted',
  removeArchive: 'See less',
  stageUpdatedAt: 'Entered stage ',
  noName: 'Must create a text description for this job.',
  noParticipants: 'Include at least one person to send.',
  nameRequired: 'Choose a name.',
  noQuantity: 'Choose a certainty.',
  noVoteQuantity: 'Choose a certainty.',
  noMultiplier: 'Choose for or against.',
  noQuestions: 'Questions should go in their own comment.',
  noQuestionUploads: 'Questions should go in their own comment.',
  collaborators: 'Collaborators',
  onlyThisGroup: 'Only this group',
  inlineAddLabel: 'Add option',
  inlineProposeLabel: 'Propose option',
  inlineAddLabelMobile: 'Option',
  promoteOption: 'Allow vote on option',
  addStoryLabel: 'Add job',
  createDialogApprovableExplanation: 'New approvable option',
  createDialogProposedExplanation: 'New proposed option',
  allowVoteSuggestion: 'Allow voting',
  noVoteSuggestion: 'Add without voting',
  voteSuggestion: 'Add with voting',
  allowVoteSuggestionMobile: 'Voting',
  uploadFilesDisplay: 'Attach New Files',
  uploadFilesTooltip: 'Drag files here or click to attach new files',
  uploadInProgress: 'Uploading Files',
  inlineAddExplanation: 'Add options for how to do this job and collect approval.',
  deactivateInlineQuestion: 'Changing stage will deactivate any dialogs associated with this job.',
  deactivateDialogQuestion: 'Do you want to deactivate and stop any further work?',
  activateDialogQuestion: 'This group is inactive. Do you want to activate and continue work?',
  changeStage: 'Change stage',
  planningMarketActivate: 'Activate group',
  tabGroupAppend: '(Group)',
  immediate: 'Critical',
  immediateToolTip: 'Critical bugs remain in the inbox notification until moved or resolved.',
  normalToolTip: 'Normal bugs send a notification that can be dismissed.',
  minorToolTip: 'Minor bugs do not send notifications.',
  readyToStartToolTip: 'Ready to Start jobs send a notification that can be dismissed.',
  notReadyToolTip: 'Not Ready jobs do not send notifications.',
  assignedJobsToolTip: 'Assigned jobs automatically send approval notifications.',
  backlogJobsToolTip: 'Use backlog jobs to find an assignee or discuss a job that is not ready for assignment.',
  bugsToolTip: 'Open a bug so it can be prioritized while waiting to be moved to a job.',
  discussionToolTip: 'Discuss suggestions and questions and create jobs from them when necessary.',
  votedOptionsToolTip: 'Only options promoted by the question creator can be approved.',
  proposedOptionsToolTip: 'Options here wait for promotion by the question creator or are permanently blocked.',
  forYouToolTip: 'Events by others create notifications that display here for you.',
  fromYouToolTip: 'Your questions, suggestions, and assignments display here for processing or poking.',
  openTasksToolTip: 'Overview of open tasks for this job',
  resolvedTasksToolTip: 'Overview of resolved tasks for this job.',
  jobOverviewToolTip: 'Overview of tasks, approvals, and status for this job.',
  jobTasksToolTip: 'Full display of all tasks for this job.',
  jobAssistanceToolTip: 'Questions, suggestions, and blockers for this job.',
  collaboratorsExplanation: 'Active participants in this job show as collaborators',
  storyFromComment: 'Move',
  storyFromCommentNav: 'Move and go to job',
  channelOptions: 'Group Options',
  marketOptions: 'Workspace Options',
  marketName: 'Choose a name for the new workspace.',
  mutingExplanation: 'Muting prevents notifications unless you are mentioned, assigned or a required approver or reviewer.',
  groupDiscussionDesc: 'About and links to external group info',
  QUESTIONYesConfig: 'Allow approve multiple options',
  QUESTIONNoConfig: 'Allow approve only one option',
  SUGGESTYesConfig: 'Everyone sees results',
  SUGGESTNoConfig: 'Only you see results',
  singlePersonMode: 'Single person mode',

  // Decision Dialog
  decisionDialogCurrentVotingLabel: 'Approvable Options',
  decisionDialogProposedOptionsLabel: 'Proposed Options',
  decisionDialogDiscussionLabel: 'Discussion',
  decisionDialogAddInvestibleLabel: 'Add',
  decisionDialogAddExplanationLabel: 'Add a new approvable option',
  decisionDialogProposeInvestibleLabel: 'Add',
  decisionDialogProposeExplanationLabel: 'Add a new proposed option',
  decisionDialogExtendDaysLabel: 'Number of days to extend deadline?',
  decisionDialogNoInvestiblesWarning: 'No votable options',
  decisionAddHelp: 'A Dialog gives you a timed box way to decide with others between options that you control.',
  backToOptionPoolWarning: 'Moving this option back to Proposed Options deletes all approval.',
  yesAndProceedDeactive: 'Archive and deactivate',
  noAndProceedDeactivate: 'Archive only',
  proceedActivate: 'Activate group',
  // Investibles in decision dialog display
  decisionDialogInvestiblesUpdatedAt: 'Last Updated:',
  dialogAddParticipantsLabel: 'Add collaborators',
  planningMarketSubscribeExplanation: 'Subscribe to approvals and reviews',
  planningMarketUnSubscribeExplanation: 'Unsubscribe from approvals and reviews',
  storyAddParticipantsLabel: 'Change assigned',
  storyApproversLabel: 'Change required approvers',
  addApproversLabel: 'Require approvers',
  doneApprovalLabel: 'Yes - move to Approved',
  moveSuggestionLabel: 'Move suggestion',
  notDoneApprovalLabel: 'No - other options',
  otherOptionsLabel: 'Other options',
  commentInJob: 'Comment in job',
  storyReviewersLabel: 'Change required reviewers',
  storyAddressedLabel: 'Add or remove collaborators to receive notifications on this job.',
  dialogEditExpiresLabel: 'Add Time',
  dialogExpiresLabel: 'Once the Dialog expires it is frozen for changes and cannot be re-activated.',
  searchParticipantsLabel: 'Search in Uclusion',
  searchParticipantsPlaceholder: 'Enter after each email address',
  inviteParticipantsEmailLabel: 'Add by email addresses',
  collaboratorNotFollowing: 'This user will not receive notifications.',

  // DecisionIvestibleSave
  decisionInvestibleSaveAddAnother: 'Save & Add Another',
  decisionInvestibleMobileAddAnother: '& Another',

  // DecisionInvestible
  decisionInvestibleYourVoting: 'Your Input',
  decisionInvestibleOthersVoting: 'Approvals',
  decisionInvestibleDescription: 'Option - Description',
  decisionProposedInvestibleDescription: 'Proposed Option - Description',
  decisionInvestibleDiscussion: 'Discussion',
  decisionInvestibleVotingBlockedMarket: 'Voting suspended until the open issue on this dialog is resolved',
  decisionInvestibleVotingBlockedInvestible: 'Voting suspended until the open issue on this option is resolved',

  // InitiativeInvestible
  initiativeInvestibleVotingBlocked: 'Voting is blocked because there is an open issue',
  initiativeInvestibleYourVoting: 'Your Input',
  initiativeInvestibleOthersVoting: 'Options',
  initiativeInvestibleDescription: 'Initiative - Description',
  investibleDescription: 'Job - Add',
  dialogDescription: 'Dialog - Description',
  dialogAddress: 'Add collaborators',
  planAddress: 'Add collaborators',
  initiativeAddress: 'Add collaborators',
  dialogExtend: 'Dialog - Delay expiration',
  initiativeExtend: 'Initiative - Delay expiration',
  initiativeInvestibleDiscussion: 'Discussion',
  initiativeVotingFor: 'For',
  initiativeVotingAgainst: 'Against',
  initiativeAddHelp: 'Create an initiative to quickly gather opinions on your next potential project.',

  // About
  sidebarNavAbout: 'About',
  aboutApplicationVersionLabel: 'Version',
  aboutMarketIdLabel: 'Dialog ID',
  aboutAccountIdLabel: 'Account ID',
  aboutAccountNameLabel: 'Account Name',
  aboutUserIdLabel: 'User ID',
  aboutUserNameLabel: 'User Name',
  aboutUclusionEmailLabel: 'Support',
  aboutClearStorageButton: 'Clear Storage',

  // Quill Editor
  quillEditorUploadInProgress: 'Uploading image(s)',

  // diff displaydeci
  diffDisplayDismissLabel: 'Hide Changes',
  diffDisplayShowLabel: 'Show Recent Changes',

  // expiration extender
  deadlineExtenderSave: 'Modify',
  deadlineExtenderCancel: 'Close',
  allowMultiVote: 'Can collaborator\'s approve more than one option?',
  allowMultiVoteQuestion: 'Allow approve multiple options?',
  allowMultiVoteQuestionMobile: 'Allow multi-option?',

  // invite linker
  inviteLinkerDirectionsDecision: 'Share this link',
  inviteLinkerDirectionsPlan: 'Click to copy this link',
  inviteLinkerCopied: 'Link copied to clipboard',
  inviteLinkerDirectionsInitiative: 'Share this link',
  inviteLinkerCopyToClipboard: 'Copy to clipboard',

  // Assignment List
  assignmentListHeader: 'Assigned to',
  emptyAssignmentHeader: 'Unassigned goes to Backlog',
  requiresInputListHeader: 'Warning: unless you resolve some comments the current assignment will move the job to Assistance.',
  reviewListHeader: 'Required reviewers (X to remove)',
  approveListHeader: 'Required approvers (X to remove)',
  followingListHeader: 'Following (X to remove)',
  cannotUnassignExplanation: 'Collaborators assigned jobs cannot be unchecked.',
  removeExplanation: 'Remove takes away all access to this workspace',
  groupRemoveExplanation: 'Unchecking removes this user from this group',
  groupRemoveAction: 'Active',
  mutedExplanation: 'Muted group',
  muteAction: 'Mute group',
  searchAssignments: 'Choose',
  everyoneGroupExplanation: 'This group automatically contains everyone in the workspace.',
  everyoneGroupAddExplanation: 'This group automatically contains everyone in the workspace and you can add to the workspace below.',
  everyoneInGroupAddExplanation: 'This group already contains everyone in the workspace.',
  searchAssigned: 'Assigned to me',
  searchAssignedMobile: 'Assigned',
  searchActive: 'Active',

  // Address List
  addressListHeader: 'Addressed to',
  todoAddressListHeader: 'Assign to',
  addressListMakeObserver: 'Make Yourself an Observer',
  addressListMakeParticipant: 'Make Yourself a Participant',

  // Dialog Archives
  dialogArchivesNotDoingHeader: 'Not Doing',
  dialogArchivesVerifiedHeader: 'Tasks Complete',
  dialogArchivesLabel: 'Group Archive',
  readyFurtherWorkHeader: 'Backlog',
  readyToStartHeader: 'Ready to Start',
  notReadyToStartHeader: 'Not Ready',
  createFurtherWorkExplanation: 'Create a new job that sends a notification demanding assignment.',
  requiresInputHeader: 'Assistance Needed',
  blockedHeader: 'Assistance Needed',

  // SignIn
  signInEmailLabel: 'Email',
  signInPasswordLabel: 'Password',
  signInNoAccount: 'Don\'t have an account? Sign up',
  signInForgotPassword: 'Forgot Password?',
  signInSignIn: 'Sign In',
  signInGithubSignIn: 'Sign In with GitHub',
  signInGoogleSignIn: 'Sign In with Google',


  // Signup
  signupNameLabel: 'Name',
  signupEmailLabel: 'Email',
  signupPasswordLabel: 'Password',
  signupPasswordHelper: '6 Characters Minimum',
  signupPasswordRepeatLabel: 'Repeat Password',
  signupPasswordRepeatHelper: 'Must match Password',
  signupSignupLabel: 'Create Account',
  signupTitle: 'Try our sandbox demo for free.',
  signupHaveAccount: 'Already have an account? Sign in',
  signupAccountExists: 'An account with that email already exists, please log in.',
  signupAccountExistsLoginLink: 'Log In',
  signupSentEmail: 'We have sent a verification email to you. Please click the link inside to continue.',
  signupCreatedUser: 'Your user is created, and a verification link has been sent to your email {email}. Please click the link inside to continue.',
  signupResendCodeButton: 'Resend Link',
  signupAgreeTermsOfUse: 'I agree to the Uclusion ',
  signupTermsOfUse: 'Terms of Use',
  signupGithubSignup: 'Sign Up with GitHub',
  signupGoogleSignup: 'Sign Up with Google',
  signInNotVerified: 'Your email ({email}) has not been verified yet. In order to use Uclusion you must verify your email address via the link we sent to your email. If you do not have that link, you may get a new one by clicking Resend Link.',

  // Forgot Password
  forgotPasswordHeader: 'Reset your password',
  forgotPasswordEmailLabel: 'Enter your email address',
  forgotPasswordCodeLabel: 'Reset Code',
  forgotPasswordNewPasswordLabel: 'New Password',
  forgotPasswordRepeatLabel: 'Repeat New Password',
  forgotPasswordSendCodeButton: 'Send Reset Code',
  forgotPasswordResetPasswordButton: 'Submit',
  forgotPasswordResendCode: 'Resend Reset Code',
  forgotPasswordEmailNotFound: 'That email address was not found',
  forgotPasswordInvalidCode: 'An invalid code was entered',
  forgotPasswordHelper: '6 Characters Minimum',
  forgotPasswordRepeatHelper: 'Must match Password',

  // Change Password
  errorChangePasswordFailed: 'Change password failed. Please try again.',
  changePasswordHeader: 'Change password',
  changePasswordNewLabel: 'New password',
  changePasswordRepeatLabel: 'Repeat new password',
  changePasswordOldLabel: 'Old password',
  changePasswordButton: 'Change Password',

  // Sign out
  signOutButton: 'Sign Out',
  errorSignOutFailed: 'Sign out failed. Please try again.',

  // Change Preferences
  changePreferencesHeader: 'Integration preferences',
  changePreferences: 'Email and Slack by workspace',
  emailEnabledLabel: 'Send notifications via email',
  slackEnabledLabel: 'Send notifications via Slack',
  changePreferencesButton: 'Update Preferences',
  slackDelayInputLabel: 'Time between alerts',
  emailDelayInputLabel: 'Time between emails for priority',
  emailDelayYellowInputLabel: 'Time between emails for not priority',
  emailDelayExplanation: 'Minimum time after notification before sending another for priority.',
  emailDelayYellowExplanation: 'Minimum time after notification before sending another for not priority.',
  slackDelayExplanation: 'Minimum time after a Slack notification before sending another.',
  doNotShowAgain: 'Do not show again',

  // your voting
  yourVotingVoteForThisPlanning: 'Approve this job',
  yourVotingVoteForThisDecision: 'Approve this option',
  approveAndGotoJob: 'Approve and go to job',
  stageAndGotoJob: 'Move and go to job',
  modifyTasks: 'Modify tasks',
  startReview: 'Start review',
  voteFor: 'Vote for',
  voteAgainst: 'Vote against',
  clearVotes: 'Your other approval will be cleared',
  addAVote: 'Approve your preferred option',
  addMultiVote: 'Approve all options you consider desirable',
  pleaseVoteStory: 'Approve this assignment',
  orStructuredComment: 'Or create a comment',
  voteDeletedStory: 'Your approval was deleted or expired. Save or modify to approve again.',
  changeVote: 'Change approval',
  changeVoteInitiative: 'Change vote',
  personChooserLabel: 'View Other Person\'s Assignments',
  clear: 'Clear',

  // add participants
  addParticipantsNewPerson: 'Not in the list? Send invite.',
  emailsSentLabel: 'Sent emails to',

  // Spinning
  spinVersionCheckError: 'There was an error. Please retry your operation.',
  loadImageError: 'Issue saving image. After you click this message, page reloads so you can retry.',

  // subscription page
  BillingHomeTitle: 'Billing Plan',

  //upgradeMenu
  billingMenuItem: 'Billing plan',
  billingFreeTier: 'You are currently on a free plan.',
  billingStandardTier: 'You are currently on a paid plan.',
  billingSubCanceled: 'Your subscription was canceled.',
  billingSubUnsubscribed: 'You have not subscribed to any plan.',
  billingSubTrial: 'Your free trial ends on {date}. Afterwards the charge is {price} monthly.',
  billingSubActive: 'Your subscription is active and charges {price} monthly.',
  billingNeedCard: 'Please add a card or use a promo code.',
  billingSubFree: 'If you add a second user free ends, but a free trial period will begin.',
  billingSubEnd: 'Your subscription will renew on {date}:',
  billingSubRestart: 'Restart subscription',
  billingSubCancel: 'Cancel subscription',
  billingMustPay: 'Create not available because trial period is over. Please go to \'Your Plan\' to update your billing information.',
  billingStartSubscription: 'Please go to manage subscription and start a free trial to enable create.',

  // payment info
  paymentInfoButtonAdd: 'Add a card',
  paymentInfoButtonUpdate: 'Update your card',
  WizardPaymentInfo: 'Add or update card',
  WizardPromoInfo: 'Apply promotion code',
  DecidePayTitle: 'Will you pay?',
  GroupWelcome: 'Welcome to a new group',
  WorkspaceWelcome: 'Welcome to the workspace',
  ApplyPromoCode: 'Apply promo code',

  // upgrade form
  upgradeFormCardName: 'Cardholder name',
  upgradeFormCardPhone: 'Cardholder phone number',
  upgradeFormCardEmail: 'Cardholder email',
  upgradeFormUpgradeLabel: 'Update card',
  upgradeFormRestartLabel: 'Restart subscription',


  // API errors
  errorUnnamedMarketAddFailed: 'There was a problem sending. Please try again.',
  errorDecisionInvestibleAddFailed: 'There was a problem adding the option.',
  errorInvestibleAddFailed: 'There was a problem adding.',
  errorInvestibleDeleteFailed: 'There was a problem deleting.',
  errorPlanningInvestibleAddFailed: 'There was a problem adding the job',
  errorInvestibleStageChangeFailed: 'There was a problem changing the stage of this job',
  errorInvestibleUpdateFailed: 'There was a problem updating.',
  errorCommentFetchFailed: 'There was a problem retrieving comments',
  errorUsersFetchFailed: 'There was a problem retrieving users',
  errorCommentSaveFailed: 'There was a problem saving your comment',
  errorInvestmentUpdateFailed: 'There was a problem updating your approval',
  errorAddParticipantsFailed: 'There was a problem adding participants',
  errorInviteParticipantsFailed: 'There was a problem inviting participants',
  errorDecisionAddFailed: 'There was a problem creating your Dialog',
  errorInitiativeAddFailed: 'There was a problem creating your Initiative',
  errorPlanningAddFailed: 'There was a problem creating your workspace',
  errorGroupAddFailed: 'There was a problem creating your group',
  errorFileUploadFailed: 'There was a problem uploading your file',
  errorEditLockFailed: 'There was a problem getting permission to edit',
  errorAcceptInvestibleFailed: 'There was a problem accepting the job. Please try again.',
  errorEditLockReleaseFailed: 'There was a problem releasing edit permission',
  errorSubmitToModeratorFailed: 'There was a problem submitting to the moderator',
  errorChangeToObserverFailed: 'There was a problem changing to approver',
  errorFollowStages: 'There was an error subscribing to the group',
  errorUnFollowStages: 'There was an error un-subscribing from the group',
  errorChangeToParticipantFailed: 'There was a problem changing to collaborator',
  errorMarketUpdateFailed: 'There was a problem editing the workspace',
  errorGroupUpdateFailed: 'There was a problem editing the group',
  errorMarketExpirationExtendFailed: 'There was a problem extending the deadline',
  errorMarketAttachFilesFailed: 'There was a problem attaching your files',
  errorInvestibleAttachFilesFailed: 'There was a problem attaching your files',
  errorInvestibleRemoveAttachedFilesFailed: 'There was a problem removing your files',
  errorMarketRemoveAttachedFilesFailed: 'There was a problem removing your files',
  errorMarketShowFailed: 'There was a problem restoring the dialog',
  errorMarketHideFailed: 'There was a problem dismissing the dialog',
  errorCommentResolveFailed: 'There was a problem resolving',
  errorCommentDeleteFailed: 'There was a problem removing',
  errorCommentMoveFailed: 'There was a problem moving comments',
  errorCommentReopenFailed: 'There was a problem reopening',
  errorMarketArchiveFailed: 'There was a problem deactivating the dialog',
  errorMarketActivateFailed: 'There was a problem activating the group',
  errorInvestibleMoveToCurrentVotingFailed: 'There was a problem moving the option to Options. Please try again.',
  errorInvestibleMoveToOptionPoolFailed: 'There was a problem moving the option back to Proposed Options. Please try again.',
  errorInvestibleFetchFailed: 'There was a problem receiving the job',
  errorStagesFetchFailed: 'There was a problem receiving the stages',
  errorGroupFetchFailed: 'There was a problem receiving the groups',
  errorGroupMembersFetchFailed: 'There was a problem receiving the group members',
  errorInvestmentsFetchFailed: 'There was a problem receiving the voting',
  errorInvestibleListFetchFailed: 'There was a problem fetching the data list',
  errorSignupFailed: 'There was a problem signing up. Please try again',
  errorResendFailed: 'There was a problem resending the link. Please try again',
  errorVerifyFailed: 'There was a problem verifying your email. Please try again',
  errorForgotPasswordCodeFailed: 'There was a problem sending your reset code. Please try again',
  errorForgotPasswordSetFailed: 'There was an error changing your password. Please try again',
  errorClearFailed: 'There was a problem clearing your data. Please try again',
  errorGetIdFailed: 'There was a problem displaying your identification.',
  errorUpdateUserFailed: 'There was a problem updating your profile. Please try again.',
  errorUpdateAbstainFailed: 'There was a problem updating your user. Please try again.',
  errorUpdateStageFailed: 'There was a problem updating allowed jobs. Please try again.',
  errorMarketFetchFailed: 'There was an error processing your invite. Please try again.',
  errorBanUserFailed: 'There was a problem removing the collaborator. Please try again.',
  errorPokeFailed: 'There was a problem poking. Please try again.',
  errorGuestUserFailed: 'There was a problem changing the collaborator. Please try again.',
  errorCancelSubFailed: 'There was a problem cancelling your subscription. Please try again.',
  errorStartSubFailed: 'There was a problem starting your subscription. Please try again.',
  errorPromoValidateFailed: 'There was a problem validating your promo code. Please try again',
  errorPromoApplyFailed: 'There was a problem applying your promo code. Please try again',
  errorRestartSubFailed: 'There was a problem restarting your subscription. Please try again.',
  errorUnbanUserFailed: 'There was a problem readmitting the collaborator. Please try again.',
  errorUpdatePaymentFailed: 'There was a problem updating your payment card. Please try again.',
  errorHomeUserFetchFailed: 'There was a problem fetching your user. Please try again.',
  errorMarketLoginFailed: 'There was a problem authenticating the operation. Please try again.',
  errorPreferenceUpdateFailed: 'There was a problem updating your preferences. Please try again.',
  errorUserLoadFailed: 'We could not load your user. Please login again.',
  errorDemoLoadFailed: 'We could not load your demo. Please login again.',
  warningAlreadyInMarket: 'You are already a part of this market.',
  homeStartTour: 'Start Tour',
  //summary
  summaryExpiredAt: 'Expired on {expireDate}',
  expiring: 'Expiring',
  expiresIn: 'Expires in ',

  //assigneeFilterDropdown
  assigneeFilterDropdownAll: 'Everyone',
  assigneeFilterLabel: 'Show jobs for:',

  //show investibles dropdown
  showInvestiblesDropdownHelp: 'Number of jobs to allow in Tasks Complete swimlane per person',
  showInvestiblesDropdownAgeHelp: 'Maximum age of jobs shown in Tasks Complete swimlanes.',
  showInvestiblesDropdownLabel: 'Maximum verified jobs shown in swimlane',
  showInvestiblesDropdownAgeLabel: 'Maximum age in days to show Tasks Complete jobs',
  showInvestiblesUnlimitedValue: 'Unlimited',

  // warnings
  warningOffline: 'You are offline',
  optionalEdit: 'These fields are optional and can be changed at any time',

  // Inbox row titles
  DecideAssignTitle: 'Will you reassign?',
  DecideStageTitle: 'Move this job?',
  AssignmentApprovalRowTitle: 'Will you approve?',
  AssignmentApprovalTitle: 'How certain are you of approving this job?',
  DecideAnswerTitle: 'Can you help answer?',
  DecideVoteTitle: 'How do you vote?',
  DecideResponseTitle: 'How do you respond?',
  DecideIdeaTitle: 'Good idea?',
  DecidePromotionTitle: 'Allow voting on option?',
  JobStatusTitle: 'Will you update?',
  JobMovedTitle: 'Okay job moved back?',
  DecideAcceptRejectTitle: 'Follow this suggestion?',
  DecideUnblockTitle: 'Help unblock?',
  NewTaskTitle: 'Questions on this task?',
  DecideResolveReopenTitle: 'Do you reopen?',
  DecideResolveTitle: 'Can you resolve?',
  DecideStartTitle: 'Can you start this bug?',
  CriticalBugTitle: 'Can you assign?',
  DecideReviewTitle: 'Will you review?',
  DecideAssignmentTitle: 'Take this job?',

  // Comments
  issuePresent: 'Blocking issue',
  nonBlockIssuePresent: 'Issue',
  questionPresent: 'Assignee has question',
  suggestPresent: 'Assignee has suggestion',
  reportPresent: 'Progress Report',
  reviewReportPresent: 'Review Report',
  issueCompose: 'Blocking issue - asks for help clearing the blocker',
  questionCompose: 'Question - invites others to add voteable options for brainstorming',
  suggestCompose: 'Suggestion - others vote on this idea and then can be converted to a task',
  issueReview: 'Blocking issue',
  questionReview: 'Question',
  suggestReview: 'Suggestion',
  todoReview: 'Task',
  issueSimple: 'issue',
  questionSimple: 'question',
  suggestSimple: 'suggestion',
  reportSimple: 'progress report',
  inProgress: 'In progress',
  markInProgress: 'Mark in progress',
  todoSimple: 'task',
  reportsSectionLabel: 'Progress',
  todoPresent: 'Task',
  issueTip: 'Use to halt an option or job until resolved.',
  questionTip: 'Question',
  suggestTip: 'Suggestion',
  reportTip: 'Used to report status.',
  reportReviewTip: 'Report made after review of this job. Required reviewers must create.',
  todoTip: 'Use to insure this item is done in this job.',
  redTip: 'Alerts team with highest priority notification',
  blueTip: 'Does not send a notification but does highlight new',
  yellowTip: 'Alerts team with notification',
  notificationLabel: 'Bug',
  notificationLabelRED: 'Critical - persistently notify',
  notificationLabelYELLOW: 'Normal - notify once',
  notificationLabelBLUE: 'Minor - no notification',
  able: 'Normal',
  makeNormal: 'Move to normal',
  convenient: 'Minor',
  createNewQUESTION: 'Ask question',
  createNewISSUE: 'Add blocker',
  createNewTODO: 'Add task',
  createNewSUGGEST: 'Add suggestion',
  createNewQUESTIONMobile: 'Question',
  createNewISSUEMobile: 'Blocker',
  createNewTODOMobile: 'Task',
  createNewSUGGESTMobile: 'Suggestion',
  createNewStatus: 'Add progress report',
  createNewApproval: 'Add approval',
  createNewVote: 'Add vote',
  addVoting: 'Add voting',
  createBug: 'Add bug',
  createComment: 'Add comment',
  createQuestion: 'Add question',
  createSuggestion: 'Add suggestion',
  createRedExplanation: 'Create a new bug with a notification that remains till resolved',
  createYellowExplanation: 'Create a new bug with a notification that can be dismissed',
  createBlueExplanation: 'Create a new bug without notification',
  taskSection: 'Tasks',
  openTodosTip: 'Expand tasks',
  closeTodosTip: 'Collapse tasks',
  openApprovalsTip: 'Expand approvals',
  closeApprovalsTip: 'Collapse approvals',
  closeDetailsTip: 'Collapse details',
  openDetailsTip: 'Expand details',
  tasksSection: 'Tasks',
  todoSection: 'Bugs',
  jobFromBugs: 'Bugs {ticketList}',
  readyToStartCheckboxExplanation: 'Ready to start',
  readyToStartExplanation: 'Ready to start jobs send a notification for assignment.',
  viewTodo: 'View',
  editTodo: 'Edit',
  markRead: 'Mark read',
  markDescriptionRead: 'Mark description read',
  markStoryRead: 'Mark job read',
  markReadMobile: 'Mark read',
  moveTodoRed: 'Move to Red',
  moveTodoYellow: 'Move to Yellow',
  moveTodoBlue: 'Move to Blue',
  startTodo: 'Start bug',
  startJob: 'Accept job',
  assignStartJob: 'Accept and skip voting',
  DecideMove: 'Reduce priority',
  DecideStartBug: 'Create job in Approved',
  DecideStartBugExisting: 'Find existing job',
  GotoBugs: 'Go to bugs',
  DecideAddReview: 'Reply to report',
  DecideAddTask: 'Add task',

  // Search
  searchBoxPlaceholder: 'Search',
  searchBoxPlaceholderMobile: ' Search',
  SearchResultsPrevious: 'Previous',
  SearchResultsNext: 'Next',
  NotificationResultJustify: 'Vote In:',
  MarketSearchResultWorkspace: 'Workspace',
  MarketSearchResultInitiative: 'Initiative',
  MarketSearchResultDialog: 'Dialog',
  InvestibleSearchResultStory: 'Job',
  InvestibleSearchResultOption: 'Option',


  // existing users
  existingUsersBanUser: 'Remove collaborator',
  existingUsersUnBanUser: 'Readmit collaborator',
  banUserWarning: 'Removing a collaborator will prevent them from seeing any future updates',
  unbanUserWarning: 'Readmitting a collaborator will allow them to see any future updates',

  // Notifications
  notificationDismiss: 'Dismiss',
  notificationDismissThread: 'Dismiss thread',

  // phone field
  phoneFieldErrorText: 'Phone should begin with plus or just be the digits',

  // AddNewWizards
  WizardContinue: 'Next',
  wizardBreadCrumb: 'Compose',
  OnboardingInviteDialog: 'Almost ready to go! Loading fully complete momentarily.',
  OnboardingWizardTitle: 'Welcome to Uclusion',
  OnboardingWizardSkipOnboarding: 'I\'ve used Uclusion before and want to go right to the app',
  OnboardingWizardStartOver: 'Cancel',
  OnboardingWizardTrySomethingElse: 'I\'ve shared the link and want to try something else',
  OnboardingWizardGoBack: 'Go back',
  OnboardingWizardContinue: 'Next',
  OnboardingWizardFinish: 'Finish',
  WorkspaceWizardCopyLink: 'Copy invite link',
  YesReady: 'Yes ready to start',
  NoReady: 'No not ready',
  OnboardingWizardSkip: 'Skip this step',
  OnboardingWizardAdvancedOptionsStepLabel: 'Job short code',
  OnboardingWizardCurrentStoryNamePlaceHolder: 'Your current job\'s name',
  OnboardingWizardCurrentStoryDescriptionPlaceHolder: 'Your current job\'s description',
  OnboardingWizardNextStoryNamePlaceHolder: 'Your next job\'s name',
  OnboardingWizardNextStoryDescriptionPlaceHolder: 'Your next job\'s description',
  OnboardingWizardCurrentStoryProgressPlaceHolder: 'Your progress so far',

  // Workspace wizard
  WorkspaceWizardNameFieldLabel: 'Workspace name',
  WorkspaceWizardTitle: 'Your workspace',

  // Job Wizard
  JobWizardGotoJob: 'Skip',
  JobWizardBacktoJob: 'Back to job',
  JobWizardBack: 'Back',
  JobWizardAssignJob: 'Assign job',
  JobWizardApproveJob: 'Approve job',
  DecisionWizardApprove: 'Approve option',
  JobWizardNewJob: 'New job',
  JobWizardExistingJob: 'Existing job',
  JobWizardStartOver: 'Start over',
  JobAssignNotDoing: 'Move to Not Doing',
  JobAssignBacklog: 'Move to Backlog',
  JobWizardReady: 'Backlog ready to start',
  JobWizardNoAssign: 'Backlog not ready',
  skipAllApprovals: 'Skip all approvals',

  //GroupWizard
  GroupWizardMeetingName: 'Your group name',
  GroupWizardGotoGroup: 'Go to group',
  GroupWizardAddMembers: 'Add people',
  ApprovalRestrictNo: 'Assignees can approve their own jobs.',
  ApprovalRestrictYes: 'Assignees need approval from others.',

  // Decision comment wizard
  DecisionCommmentWizardTerminate: 'Back to option',
  InitiativeCommmentWizardTerminate: 'Back to suggestion',

  // Approve Wizard
  ApprovalWizardApprove: 'Approve job',
  ApprovalWizardAccept: 'Accept job',
  ApproveOwnAssignmentTitle: 'Approve assignment?',
  ApproveOwnAssignmentFullTitle: 'How certain are you of taking this assignment?',
  ApprovalWizardBlock: 'Block instead',
  WizardJobAssistance: 'Requires assistance',
  ISSUEApproveWizard: 'Create issue',
  QUESTIONApproveWizard: 'Create question',
  SUGGESTApproveWizard: 'Create suggestion',
  REPORTApproveWizard: 'Create report',
  CommentAddStartReviewREPORT: 'Create report',
  TODOApproveWizard: 'Create task',

  //Status Wizard
  StatusWizardEstimate: 'Estimate date',
  StatusWizardEstimateStart: 'Estimate and start',
  StatusWizardReport: 'Report progress',
  StatusWizardDate: 'Set date',
  StatusWizardDateStart: 'Set date and start',

  //Decide Wizard
  DecideWizardContinue: 'Continue to full display',
  DecideWizardMute: 'Mute this thread',
  DecideWizardApprove: 'Approve open option',
  DecideResolveAndGoJob: 'Resolve and go to job',
  DecideResolveAndMoveToJob: 'Resolve and move to job',
  DecideWizardReassign: 'Reassign',
  DecideJobOwner: 'Stop assignment',
  moveToTaskLabel: 'Move to task',
  moveReplyToTaskLabel: 'Move reply to task',
  DecideMoveBacklog: 'Move to backlog',
  RejectAssignment: 'Reject assignment',
  BugWizardMoveToBug: 'Move to bug',
  BugWizardMoveToJob: 'Move to job',

  //SignupWizard
  SignupWizardTitle: 'What do you want to create?',
  SignupWizardWelcome: 'Welcome to Uclusion!',
  SignupWizardQuestion: 'What do you want to do?',
  SignupWizardDialog: 'Dialog for brainstorming options outside your team.',
  SignupWizardInitiative: 'Initiative to measure support for a single option outside your team.',

  //AddOptionWizard
  AddOptionWizardTitle: 'Your Option',
  AddOptionWizardOptionNameStepLabel : 'Option Name',
  AddOptionWizardOptionDescriptionStepLabel: 'Option Description',
  AddOptionWizardOptionNamePlaceHolder: 'Your option\'s name',
  AddOptionWizardOptionDescriptionPlaceHolder: 'Your option\'s description',
  AddOptionWizardCancelOption: 'Cancel New Option',

  //ComposeWizard
  JOBComposeLabel: 'Job - holds assignments, tasks, questions, suggestions, blockers, and status',
  QUESTIONComposeLabel: 'Question - can have approvable options and movable to a job',
  SUGGESTComposeLabel: 'Suggestion - voted for or against and movable to a job as suggestion or task',
  TODOComposeLabel: 'Bug - movable to a job as a task',
  GROUPComposeLabel: 'Group - for notification addressing and views of other types',
  compose: 'Compose',


  //UnblockWizard
  DecideMoveToBacklog: 'Move job to backlog',

  // StepHeaders
  StepHeadersStepCount: 'Step {currentStep} of {totalSteps}',

  IdentityChangeAvatar: 'Change your avatar',
  changeAvatarPreferences: 'Gravatar',
  IdentityTermsOfUse: 'Terms of Use',

  VideoDialogTitle: 'Insert a Video',
  VideoDialogTitleAddButton: 'Insert',
  VideoDialogUrl: 'URL for Video',
  VideoDialogUrlPlaceholder: 'Video URL',
  VideoDialogExplanation: 'Please provide the URL of the video to insert. We integrate with YouTube, Loom, and Vimeo, and support links to most video format files.',

  LinkDialogTitle: 'Add a Link',
  LinkDialogExplanation: 'Please provide the URL of the link you want to add.',
  LinkDialogUrl: 'URL for Link',
  LinkDialogUrlPlaceHolder: 'Link URL',


});

export default messages;
